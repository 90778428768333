@import '../assets/styles/colors.scss';

.sales-representative {
  &-section-header {
    margin: 0px;
    margin: 8px 0px;
    font-size: 24px;
    font-family: $FontBold;
  }
}
