@import '../../../assets/styles/colors.scss';

.order-information {
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  .letter-spacing {
    letter-spacing: 0.08em;
  }

  .pointer {
    position: absolute;
    width: fit-content;
    font-size: 14px;
    letter-spacing: 0.08em;
    z-index: 0;
  }

  .tooltip-content {
    top: 30px;
    white-space: nowrap;
    z-index: 999;
    box-shadow: 0px 0px 4px 1px $shadow-black;
    cursor: pointer;
  }
}