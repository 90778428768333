.store-loading-animation {
  width: 280px;
  height: 140px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards, breathe 1.75s ease-in-out infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes breathe {

  0%,
  100% {
    width: 280px;
    height: 140px;
    object-fit: contain;
  }

  50% {
    width: 260px;
    height: 130px;
    object-fit: contain;
  }
}