@import '../../assets/styles/colors.scss';

.support-modal {
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 5;

  &__button {
    cursor: pointer;
    border: none;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background-color: $red-rose;
    box-shadow: 0px 0px 27px -6px #00000099;

    &-remove-box-shadow {
      box-shadow: transparent;
    }
  }

  &__content {
    z-index: 3;
    box-sizing: border-box;
    box-shadow: 0px 0px 27px -18px #00000099;
    position: absolute;
    border-radius: 16px;
    width: 360px;
    background-color: $white;
    max-height: 90vh;
    bottom: 0;
    right: 0;
    overflow-y: auto;

    &__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $white;
      background-color: $red-rose;
      padding: 16px;

      &__group {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        &__title {
          font-family: $FontBold;
          font-size: 32px;
          margin: 0;
          padding: 0;
        }

        &__close-modal-button {
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }

      p {
        font-family: $FontNormal;
        margin: 0;
        padding: 0;
        margin-bottom: 8px;
      }

      &__open-form-button {
        background-color: transparent;
        border: none;
        outline: none;
        color: $white;
        font-family: $FontBold;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          color: $light-black;
        }
      }
    }

    &__search-doc {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
    }

    &__form {
      padding: 16px;

      &__textarea {
        display: inline-block;
        max-height: 80;
        min-height: 80;
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        border-color: $light-gray;
        box-sizing: border-box;
        margin-bottom: 24px;

        &:active,
        &:focus {
          border-color: $red-rose;
        }

        &-invalid {
          border-color: $red-rose;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    bottom: 16px;
    right: 16px;
    z-index: 1;

    &__content {
      margin-right: 6px;
    }
  }
}

.opened {
  display: inline-block;
  -webkit-transition: height 0.4s ease-in-out;
  -moz-transition: height 0.4s ease-in-out;
  -o-transition: height 0.4s ease-in-out;
  transition: height 0.4s ease-in-out;
  height: 700px;

  &__content {
    height: 100vh !important;
    max-height: 98vh;
  }
}

.closed {
  -webkit-transition: height 0.4s ease-in-out;
  -moz-transition: height 0.4s ease-in-out;
  -o-transition: height 0.4s ease-in-out;
  transition: height 0.4s ease-in-out;
  height: 0px;
}
