@import '../../assets/styles/colors.scss';

.banner {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: $gray-white;
  overflow: hidden;

  &-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 80%;
    max-width: 1440px;
  }

  &-title {
    margin: 0;
    color: $light-black;
    font-family: $FontBold;
    font-size: 48px;
    position: relative;
    line-height: 50px;
    z-index: 2;
    margin-bottom: 32px;
  }

  &-plus {
    color: $red-rose;
    font-family: $KaushanScript;
    font-style: italic;
    font-size: 80px;
    position: absolute;
    bottom: 10px;
    z-index: 2;
  }

  &__features {
    display: flex;
    flex-direction: column;
    font-family: $FontBold;
    z-index: 2;

    &-group {
      display: flex;
      align-items: center;
      font-family: $FontNormal;
      letter-spacing: 1.5px;
      color: $black;
      font-size: 14px;
    }

    &-label {
      line-height: 20px;
    }

    .dot {
      color: $red-rose;
      font-size: 24px;
      line-height: 16px;
      margin: 0px 8px;
      z-index: 2;
    }
  }

  &-image {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 60%;
    object-fit: fill;
  }

  @media only screen and (max-width: 1024px) {
    &-container {
      width: 90%;
    }
  }

  @media only screen and (max-width: 768px) {
    background-color: $red-rose;
    height: 300px;

    &-container {
      width: 95%;
      align-items: center;
    }

    &-title {
      font-size: 45px;
      color: $white;
    }

    &-plus {
      color: $black;
    }

    &__features {
      &-group {
        font-size: 12px;
        color: $white;
        letter-spacing: 1px;
        font-size: 12px;
      }

      .dot {
        color: $black;
        margin: 0px 4px;
        line-height: 12px;
      }
    }

    &-image {
      display: none;
    }
  }

  @media only screen and (max-width: 535px) {
    background-color: $red-rose;

    &-container {
      width: 98%;
    }

    &-title {
      font-size: 33px;
      line-height: 40px;
    }

    &-plus {
      font-size: 55px;
    }

    &__features {
      &-group {
        letter-spacing: 1.8px;
      }
    }
  }

  @media only screen and (max-width: 535px) {
    &-title {
      font-size: 28px;
      line-height: 30px;
    }

    &-plus {
      font-size: 45px;
      bottom: 6px;
    }

    &__features {
      &-group {
        font-size: 11px;
        letter-spacing: 1px;
      }
    }
  }
}
