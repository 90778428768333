@import '../../assets/styles/colors.scss';

.pedidis-container {
  overflow-y: auto;

  @media only screen and (max-width: 1024px) {
    overflow-y: visible;
  }
}

.order-details-modal {
  width: 50%;
  min-width: 650px;
  height: 500px;
  border-radius: 8px;
  box-shadow: 0px 0px 14px $shadow-black;
  box-sizing: border-box;
  padding: 8px;
  overflow: hidden;

  @media only screen and (min-width: 1024px) {
    height: fit-content;
    box-shadow: none;

  }
}