@import '../../../assets/styles/colors.scss';

.conclue-order-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  overflow-x: hidden;
}

.position-button {
  display: flex;
  align-items: center;
}

.complete-order {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  font-size: 14px;
  padding: 16px;

  @media only screen and (max-width: 701px) {
    max-width: 95%;
    justify-content: center;
  }

  &-title-page {
    font-size: 18px;
    font-family: $FontBold;
    margin: 16px 0px;
  }

  &-section {
    margin-bottom: 16px;
    background: $white;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 16px;
    box-shadow: 0px 1px 4px #00000040;
  }

  &-section-title {
    display: flex;
    margin-bottom: 8px;
    font-family: $FontBold;
    color: $store-primary-color;
  }

  &-padding-section {
    padding: 0px 4px;
    box-sizing: border-box;
  }

  &__container {
    margin-bottom: 8px;

    &-infs {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &-name-product {
        display: inline-flex;
        width: 100%;
      }

      &-price {
        font-family: $FontBold;
        color: $green;

        small {
          font-family: $FontLight;
          color: $light-black;
        }
      }
    }
  }

  .complements {
    box-sizing: border-box;
    padding-left: 4px;
    font-size: 12px;
  }

  &-user-data {
    display: flex;
    flex-direction: column;
  }

  &__payment {
    display: inline-flex;
    align-items: center;

    &-icon {
      position: relative;
      bottom: -2px;
      margin-right: 8px;
    }
  }

  &-no-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    position: relative;

    &-coupon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &-order-value {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-value-type {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        color: $store-primary-color;
        font-family: $FontBold;

        small {
          color: $light-black;
          font-family: $FontLight;
        }

        &-price {
          font-family: $FontBold;
          color: $green;
        }
      }
    }

    .remove-coupon {
      font-size: 12px;
      position: absolute;
      bottom: -16px;
      right: 0px;
    }

    .align-buttons {
      flex-direction: row;
      justify-content: space-between;

      button:nth-child(2) {
        margin-left: 16px;
        max-width: 50%;
      }
    }

    &-count-order {
      position: absolute;
      left: 180px;
      top: -7px;
      font-size: 12px;
      width: 100%;
    }
  }

  &-textarea {
    height: 123px;
    min-height: 123px;
    max-height: 123px;
    resize: none;
    width: 97%;
    min-width: 97%;
    max-width: 97%;
    border-radius: 8px;
    border: 1px solid $gray;
    outline: none;
    font-family: $FontNormal;
    font-size: 14px;
    padding: 4px;
    margin-top: 8px;
  }
}
