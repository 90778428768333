.count-input {
  height: 30px;
  width: 70px;
  min-width: 70px;
  max-width: 70px;

  .count-button {
    width: 24px;
    height: 30px;
  }

  .qtty-label {
    width: 15px;
  }
}