.configuration-steps {
  &-label {
    font-size: 10px;
  }

  @media only screen and (min-width: 768px) {
    &-label {
      font-size: 16px;
    }

    &-icon {
      font-size: 14px;
    }

  }
}