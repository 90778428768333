body {
  background-color: var(--color-light-gray);
}

.store-products {
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
}