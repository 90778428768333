@font-face {
  font-family: MontserrattBold;
  src: url('../fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: MontserrattNormal;
  src: url('../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: MontserratLight;
  src: url('../fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: KaushanScriptRegular;
  src: url('../fonts/KaushanScript-Regular.ttf');
}

//fonts
$FontBold: MontserrattBold;
$FontNormal: MontserrattNormal;
$FontLight: MontserratLight;
$KaushanScript: KaushanScriptRegular;

//Red
$red: #e4001b;
$light-red: #e10036;
$red-rose: #f10542;
$shadow-red: #e4001b27;
$dark-red: #b30015;

//Black
$black: #232323;
$light-black: #3e3e3e;
$shadow-black: #00000026;

//gray
$gray: #bdbdbd;
$gray-white: #f8f8f8;
$light-gray: #dcdcdc;
$dark-gray: #828282;

//BLUE
$blue: #61dafb;
$medium-blue: #31354a;
$dark-blue: #200e32;

//White
$white: #ffffff;
$dark-white: #f1f1f1f1;

//Green
$green: #02b345;
$green-activated: #27ae60;

//Orange
$orange: #ff6b00;
$light-orange: #cd5600;

//Purple
$purple: #5600e1;

// Store colors variables
$store-primary-color: var(--store-primary-color, $red-rose);