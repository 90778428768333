@import '../../assets/styles/colors.scss';

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: $black;
  overflow: hidden;
  padding: 16px;

  &-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 80%;
    max-width: 1440px;
  }

  &__content {
    box-sizing: border-box;
    padding: 64px 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    &-image {
      width: 20%;
      max-width: 150px;
    }

    &__topics {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      width: 100%;

      &-title {
        color: $white;
        margin: 0px;
        margin-bottom: 16px;
        font-size: 24px;
      }

      &-list {
        list-style: none;
        margin: 8px 0px !important;
        padding: 0px !important;
      }

      &-item {
        margin: 16px 0px !important;
        font-size: 14px;
        color: $white;
        font-family: $FontLight;
        cursor: pointer;

        * {
          color: $white;
          text-decoration: none;
        }
      }
    }
  }

  &-message {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-family: $FontLight;
  }

  @media only screen and (max-width: 1024px) {
    &-container {
      width: 90%;
    }
  }

  @media only screen and (max-width: 768px) {
    &-container {
      width: 95%;
      align-items: center;
    }

    &__content {
      &-image {
        display: none;
      }

      &__topics {
        flex-direction: column;
      }
    }
  }
}
