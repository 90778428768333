.user-cart {
  .cart-header {
    max-width: 768px;
  }

  .cart-products {
    height: 100%;
    margin-top: 52px;
    margin-bottom: 90px;
  }

  @media only screen and (min-width: 768px) {
    padding-bottom: 0px;
    background-color: var(--color-light-gray);
    overflow-y: auto;

    .user-cart-content {
      max-width: 768px;
    }

    .cart-products {
      height: auto;
      padding-bottom: 80px;
      background: var(--bs-white);
    }
  }
}