.configure-store {
    &-title {
        font-size: 28px;
    }

    &-sub-title {
        font-size: 12px;
    }


    @media only screen and (min-width: 768px) {
        &-title {
            font-size: 42px;
        }

        &-sub-title {
            font-size: 16px;
        }
    }
}