@import '../../../assets/styles/colors.scss';

.title {
  font-family: $FontBold;
  font-size: 24px;
  margin: 8px 0px;
}

.new-category-body {
  display: flex;
  width: 100%;
  margin: 8px 0px;

  @media only screen and (max-width: 425px) {
    flex-direction: column;

    .input-style {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

.card-toggle {
  width: 82%;
  margin: 8px 0px;
  align-items: center;

  @media only screen and (max-width: 425px) {
    align-items: flex-start;
  }
}

.btn-toggle {
  justify-content: end !important;
  padding: 8px 0px;
}

.card-btn-save {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-modal {
  width: 100%;
  max-width: none !important;
}
