@import '../../assets/styles/colors.scss';

.representative-header {
  padding: 0px 16px;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-open {
    -webkit-transition: height 0.5s ease-in-out;
    -moz-transition: height 0.5s ease-in-out;
    -o-transition: height 0.5s ease-in-out;
    transition: height 0.5s ease-in-out;
    height: 175px;
    background-color: $light-gray;
  }

  &-closed {
    -webkit-transition: height 0.5s ease-in-out;
    -moz-transition: height 0.5s ease-in-out;
    -o-transition: height 0.5s ease-in-out;
    transition: height 0.5s ease-in-out;
    height: 0px;
    background-color: $light-gray;
  }

  &-hamburguer-icon {
    display: none;
    position: absolute;
    right: 8px;
    top: 16px;
    z-index: 5;
  }

  &__content {
    &-open {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 100%;
      -webkit-transition: top 0.5s ease-in-out;
      -moz-transition: top 0.5s ease-in-out;
      -o-transition: top 0.5s ease-in-out;
      transition: top 0.5s ease-in-out;
      top: 0px !important;
    }

    &-closed {
      position: relative;
      width: 100%;
      -webkit-transition: top 0.5s ease-in-out;
      -moz-transition: top 0.5s ease-in-out;
      -o-transition: top 0.5s ease-in-out;
      transition: top 0.5s ease-in-out;
      top: -200px !important;
    }
  }

  &__left-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-logo {
      width: 150px;
    }

    &__routes {
      margin-left: 32px;
      font-family: $FontNormal;
      color: $black;
      font-size: 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-route {
        font-family: $FontNormal;
        margin: 1px 12px;
        cursor: pointer;
        color: $black;
        text-decoration: none;

        &-active {
          text-decoration: none;
          font-family: $FontBold;
          color: $red-rose;
          margin: 1px 12px;
          border-bottom: 2px solid $red-rose;
        }

        &.exit {
          color: $red-rose;
          background-color: transparent;
          border: none;
          font-family: $FontNormal;
          font-size: 16px;
        }
      }
    }
  }

  &__right-section {
    display: flex;
    align-items: center;

    &-user-name {
      font-family: $FontNormal;
      color: $black;
      margin-right: 4px;
    }

    &-btn-logout {
      border: none;
      background-color: transparent;
      color: $black;
      font-family: $FontNormal;
      cursor: pointer;
      outline: none !important;

      &-label {
        color: $red-rose;
        font-family: $FontNormal;
        outline: none !important;
        letter-spacing: 1px;

        &:hover {
          border-bottom: 1px solid $red-rose;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    position: relative;
    height: auto;
    position: relative;
    padding: 0px;
    background-color: $light-gray;

    &-hamburguer-icon {
      display: block;
    }

    &__left-section {
      width: 100%;
      display: flex;
      align-items: center;

      &-logo {
        position: absolute;
        left: 8px;
        z-index: 1;
        width: 120px;

        @media only screen and (max-width: 425px) {
          width: 90px;
          left: 0px;
        }
      }

      &__routes {
        flex-direction: column;
        width: 100%;
        margin: 0px;

        &-route {
          margin: 0px;
          font-family: $FontBold;
          padding: 8px 0px;
          width: 100%;
          text-align: center;

          &-active {
            margin: 0px;
            background-color: $gray;
            padding: 8px 0px;
            width: 100%;
            text-align: center;
            border-bottom: none;
          }
        }
      }
    }

    &__right-section {
      display: none;
    }
  }
}

.representative-content {
  margin-top: 16px;
  padding: 0px 16px;
}
