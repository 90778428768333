@import '../../../../../assets/styles/colors.scss';

.dropdown-complements {
  width: 100%;
  max-width: 305px;
  z-index: 4;
  position: relative;

  @media only screen and (max-width: 425px) {
    max-width: 95% !important;
  }
}

.inputs-radio-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}


.input-style {
  outline: none;
  width: 85%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid $gray;
  margin-top: 8px;
}

.box-count {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  height: 40px;
  border: 1px solid $light-gray;
  border-radius: 8px;
}

.down-count {
  border: none;
  background-color: transparent;
  font-weight: bold;
  font-size: 24px;
  color: $gray;
  padding-left: 8px;
  padding-bottom: 4px;
  cursor: pointer;
}

.down-count:hover {
  color: $red;
}

.count {
  font-size: 16px;
  color: $light-black;
  border: none;
  width: 90px;
  outline: none;
  text-align: center;
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.up-count {
  border: none;
  background-color: transparent;
  font-weight: bold;
  font-size: 24px;
  color: $gray;
  padding-right: 8px;
  cursor: pointer;
  z-index: 3;
}

.up-count:hover {
  color: $red;
}

.invalid-input {
  border: 1px solid $red;
}

.input-style-custom {
  width: 87% !important;
  position: absolute;
  z-index: 5;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  top: 0px;
}