@import '../../assets/styles/colors.scss';

.dropDown-items {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50px;
  width: 100%;
  background-color: $white;
  left: -1px;
  text-align: center;
  border-radius: 8px;
  overflow-x: hidden;
  border: 1.5px solid $gray;
  max-height: 25vh;
  overflow-y: scroll;
  z-index: 5;
}

.options-selected {
  padding: 8px;
  font-size: 16px;
  text-align: center;
}

.options-selected:hover {
  background-color: $light-gray;
}
