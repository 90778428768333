@import '../../assets/styles/colors.scss';

.representative-account {
  display: flex;
  flex-direction: column;

  &__card {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px #00000040;
    border-radius: 8px;
    justify-content: center;
    padding: 8px;
  }

  &__data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 8px;
    font-size: 14px;

    &-align-row {
      display: inline-flex;
      align-items: center;
      width: 80%;
      justify-content: space-between;
    }

    &-type {
      font-family: $FontNormal;
      font-size: 14px;

      small {
        font-size: 10px;
        font-family: $FontLight;
      }
    }

    &-value {
      font-family: $FontBold;
      font-size: 14px;
    }

    &-icon {
      cursor: pointer;
    }
  }

  &__configure-pix {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    h4 {
      font-size: 16px;
      color: $black;
      text-align: left;
      align-items: flex-start;
      display: flex;
      width: 100%;
      margin: 8px 0px !important;
      font-family: $FontBold;
    }

    &__pix-identification {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      overflow-x: scroll;
      padding: 8px 0px;

      &::-webkit-scrollbar {
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
      }

      &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: 8px;
        margin: 0px 4px;
        border: 1px solid $black;
        border-radius: 8px;
        min-width: 120px;
        white-space: nowrap;
        cursor: pointer;

        &.selected-card {
          border-color: $red-rose;
        }
      }
    }

    &__pix-value {
      display: flex;
      width: 100%;
      margin: 8px 0px;
      align-items: center;

      &-button {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  @media only screen and(max-width: 768px) {
    align-items: center;
  }
}
