@import '../../../assets/styles/colors.scss';



.filter-orders {
  .order-status-filter {
    cursor: pointer;
  }

  .selected-status {
    background-color: $red;
    color: $white;
    border-radius: 8px;
  }

  @media only screen and (max-width: 425px) {
    width: 100%;
    min-width: 100%;
    margin: 0px;
    margin-top: 8px;
  }
}