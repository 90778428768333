.dl-switch-button {
    button {
        font-size: 12px;
    }

    @media only screen and (min-width: 768px) {
        button {
            font-size: 14px;
        }
    }

}