@import '../../../../assets/styles/colors.scss';

.payments-methods {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 380px 160px;
  justify-content: start;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1.2fr 0.8fr;
    grid-template-rows: auto;
    grid-row-gap: 8px;
  }

  @media only screen and (max-width: 425px) {
    grid-template-columns: 1fr;
  }

  &-group {
    font-family: $FontBold;
  }

  &-column {
    display: flex;
    flex-direction: column;
    padding: 0px;

    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  .MuiButtonBase-root {
    padding: 4px 8px;
  }

  &-payment {
    display: inline-flex;
    align-items: center;
  }

  &-label-method {
    font-family: $FontNormal;
    margin-left: 8px;
  }
}