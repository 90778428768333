.open {
  -webkit-transition: left 0.5s ease-in-out;
  -moz-transition: left 0.5s ease-in-out;
  -o-transition: left 0.5s ease-in-out;
  transition: left 0.5s ease-in-out;
  left: 0%;

  @media only screen and (min-width: 1024px) {
    width: 250px !important;
  }
}

.close {
  -webkit-transition: left 0.5s ease-in-out;
  -moz-transition: left 0.5s ease-in-out;
  -o-transition: left 0.5s ease-in-out;
  transition: left 0.5s ease-in-out;
  left: -100%;

  @media only screen and (min-width: 1024px) {
    width: 250px !important;
  }

}