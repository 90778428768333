@import '../../assets/styles/colors.scss';

.hamburguer-button {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  width: 32px;
  height: 32px;

  &__content {
    width: 30px;
    height: 3px;
    background: $red;
    border-radius: 5px;
    box-shadow: 0 2px 5px $shadow-black;
    transition: all 0.5s ease-in-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 30px;
      height: 3px;
      background: $red;
      border-radius: 5px;
      box-shadow: 0 2px 5px $shadow-black;
      transition: all 0.5s ease-in-out;
      left: 0px;
    }

    &::before {
      transform: translateY(-10px);
    }

    &::after {
      transform: translateY(10px);
    }
  }
}

.hamburguer-button-open .hamburguer-button__content {
  transform: opacity(0%);
  background: transparent;
  box-shadow: none;
}

.hamburguer-button-open .hamburguer-button__content::before {
  transform: rotate(45deg);
}

.hamburguer-button-open .hamburguer-button__content::after {
  transform: rotate(-45deg);
}

.orders {
  background: $white;

  &::before,
  &::after {
    background: $white;
    left: 0px;
  }
}