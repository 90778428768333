.store-menu {
  .product-image {
    max-width: 100%;
    object-fit: contain;
  }

  .product-complements {
    padding-bottom: 72px;
  }

  @media only screen and (min-width: 768px) {
    padding-bottom: 0px;
    background-color: var(--color-light-gray);
    overflow-y: auto;

    .product-details {
      max-width: 768px;
    }

    .product-complements {
      padding-bottom: 64px;
    }

    .product-image {
      max-width: 333px;
      object-fit: contain;
      border-radius: 16px;
    }
  }
}