.dl-onboarding {
  grid-template-columns: 0.7fr 1fr;
  background: var(--color-light-gray);

  &-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-red-rose);
    opacity: 0.5;
  }

  &-element {
    position: absolute;
    width: 80%;
    height: 70%;
    bottom: 0;
    right: 0;
    border-radius: 304.5px 0px 0px 0px;
    background-color: var(--color-black);
    opacity: 0.2;
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 0fr;
  }
}