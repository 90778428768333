@import '../../../../assets/styles/colors.scss';

.textarea {
  max-height: 170px;
  min-height: 170px;
  border: 1px solid $gray;
  padding-top: 4px;
  resize: none;
  width: 100%;
}

.upload-product-image {
  &__image {
    border: 2px dashed $dark-gray;
    overflow: hidden;
    border-radius: 8px;

    input[type='file'] {
      display: none;
    }

    &__container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 180px;
      width: 100%;

      &__image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        border-radius: 8px;
        object-fit: cover;
      }

      &__btn-upload {
        margin-top: 16px;
        border: 1px solid $red;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        border-radius: 8px;
        color: $red;
        font-weight: bold;

        &-has-image {
          position: absolute;
          bottom: 10px;
          background-color: $red;
          color: $white;
        }
      }
    }
  }




  //   .input-style {
  //     width: 95% !important;
  //   }
  // }

  // &__buttons {
  //   margin-top: 16px;
  //   width: 100%;
  //   display: flex;
  //   justify-content: space-between;
  // }
}