.documentation {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px 16px 0px 16px;
  overflow: hidden;
  position: relative;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 585px;
  }

  @media only screen and (max-width: 768px) {
    width: auto !important;
  }
}

.company-details-funcs {
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 200px;
  }
}