@import '../../assets/styles/colors.scss';

.store-login__fields-section__fields__content__input {
  margin-bottom: 16px;

  &-small-mb {
    margin-bottom: 8px !important;
  }
}

.warning-not-subscription {
  padding: 8px;
  background-color: $shadow-red;
  color: $red;
  font-weight: bold;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
}
