@import '../../assets/styles/colors.scss';

.documentations {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-y: auto;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 585px;

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 8px;
      margin-bottom: 16px;

      &__title {
        font-family: $FontBold;
        font-size: 32px;
      }

      &__input {
        width: 100%;
        max-width: 585px;
        height: 46px;
        border-radius: 16px;
        border: 1px solid $red-rose;
        outline: none;
        padding-left: 8px;
        font-family: $FontNormal;
      }
    }

    &__documentations {
      font-family: $FontNormal;

      &__section {
        display: block;
        margin-bottom: 8px;

        &__type {
          display: inline-block;
          color: $red-rose;
          font-family: $FontBold;
          text-decoration: none;
          margin-bottom: 8px;

          &-light-color {
            color: $white;
          }

          &:hover {
            color: $light-black;
          }

          &__content {
            display: inline-block;
            display: flex;
            flex-direction: column;
            color: $light-black;
            text-decoration: none;
            padding: 0px 0px 8px 16px;
            list-style: circle;

            &:hover {
              color: $red-rose;
            }
          }
        }
      }
    }
  }
}
