@import '../../../assets/styles/colors.scss';

.order-details-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section-container {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  background: $white;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0px 1px 4px #00000040;

  &-title {
    display: flex;
    margin-bottom: 8px;
    font-family: $FontBold;
    color: $store-primary-color;
  }

  &-date {
    font-family: $FontNormal;
    font-size: 14px;
  }

  &-inline {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }

  &-contact {
    border: none;
    background-color: $store-primary-color;
    border-radius: 8px;
    color: $white;
    font-family: $FontBold;
    font-size: 14px;
    padding: 8px 4px;
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 8px;
  }

  &-cancel-order {
    border: none;
    background-color: transparent;
    margin-bottom: 8px;
    border-radius: 8px;
    color: $dark-red;
    font-family: $FontBold;
    font-size: 14px;
    cursor: pointer;
  }

  &__infs-store {
    display: flex;
    width: 100%;

    &__left-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 35%;

      &-logo {
        width: 115px;
        height: 70px;
        border-radius: 8px;
        object-fit: contain;

        -webkit-box-shadow: 0px 0px 4px 2px $light-gray;
        -moz-box-shadow: 0px 0px 4px 2px $light-gray;
        box-shadow: 0px 0px 4px 2px $light-gray;
      }

      &-status {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
        border: 1px solid $light-gray;
        border-radius: 16px;
        color: $green;
        font-family: $FontNormal;
        font-size: 14px;
        margin-top: 4px;
      }

      &-dot {
        font-size: 18px;
        font-family: $FontBold;
      }
    }

    &__right-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-left: 5%;
      width: 100%;

      &__order {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &-id {
          font-family: $FontBold;
          color: $store-primary-color;
          font-size: 14px;
        }

        &-date {
          font-size: 12px;
          font-family: $FontLight;
          margin-left: 16px;
        }

        &-price {
          small {
            color: $dark-gray;
            font-family: $FontLight;
            font-size: 10px;
          }
          color: $green;
          font-family: $FontBold;
          font-size: 14px;
        }
      }

      &__store {
        display: flex;
        flex-direction: column;

        &-bold {
          font-family: $FontBold;
          font-size: 14px;
        }

        &-default {
          font-size: 13px;
        }
      }
    }
  }

  &__itens-order {
    width: 100%;
    font-size: 14px;
    font-family: $FontNormal;
    margin-bottom: 8px;

    &-product {
      font-family: $FontNormal;
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
    }

    &-complement {
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      padding-left: 8px;
      box-sizing: border-box;
    }

    &-price {
      small {
        color: $dark-gray;
        font-family: $FontLight;
        font-size: 10px;
      }
      color: $green;
      font-family: $FontBold;
      font-size: 14px;
    }
  }
}
