@import '../../assets/styles/colors.scss';

.input-content {
  position: relative;

  &-error-input {
    color: $red;
    font-family: $FontLight;
    font-size: 12px;
    position: absolute;
    bottom: -8px;
    left: 2px;
  }

  &-label {
    font-family: $FontLight;
    font-size: 13px;
  }

  &__container__input {
    position: relative;

    &-icon-eye {
      position: absolute;
      top: 12px;
      right: 4px;
      cursor: pointer;
    }

    &__show-modal-again {
      background-color: transparent;
      border: none;
      font-family: $FontNormal;
      position: absolute;
      bottom: -10px;
      right: 0px;
      cursor: pointer;

      &:hover {
        color: $red;
      }
    }
  }
}

.dropdown-container {
  position: relative;
  z-index: 3;

  input {
    cursor: pointer !important;
  }

  &__arrows {
    position: absolute;
    right: 8px;
    top: 33px;
    background: none;
    border: none;
    cursor: pointer;
  }

  &__options {
    width: 100%;
    position: absolute;
    top: 75px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: $white;
    border: 1px solid $light-gray;
    border-radius: 8px;
    overflow: hidden;
    max-height: 155px;
    overflow-y: auto;

    &__option {
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;
      padding: 16px;
      box-sizing: border-box;
      font-family: $FontNormal;
      cursor: pointer;
      border-bottom: 1px solid $light-gray;

      &-selected {
        background-color: $dark-white;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: $dark-white;
      }
    }
  }
}
