@import '../../assets/styles/colors.scss';

h4 {
    color: $red;
    margin: 0;
}

.simple-text {
    margin-bottom: 8px;
    margin-top: 8px;
    font-size: 14px;
}

.configuracao {
    padding: 16px;
    width: 100%;

    .line-nav {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;

        .card-config {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-bottom: 1px solid $gray;
            padding: 0px 8px;
            color: $dark-gray;
            padding-bottom: 8px;
            z-index: 1;
            text-decoration: none;

            &:hover {
                padding-bottom: 7px;
                border-bottom: 2px solid $red;
                color: $red;
            }
        }

        .card-active {
            border-bottom: 2px solid $red;
            color: $red;
            font-weight: bold;

            &:hover {
                padding-bottom: 8px !important;
            }
        }
    }
}

.conf-store {
    display: flex;
    flex-direction: column;
}

.custom-file-upload {
    margin-top: 16px;
    border: 1px solid $red;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 8px;
    color: $red;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    .configuracao {
        width: initial;
    }
}

@media only screen and (max-width: 690px) {
    .simple-text {
        text-align: center !important;
    }

    .configuracao {
        width: initial;

        .line-nav {
            flex-direction: column;
            justify-content: center;

            .card-config {
                padding: 8px;
            }
        }
    }
}