@import '../../../assets/styles/colors.scss';

.content-order-note {
  * {
    color: $black !important;
  }
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;

  .store-name {
    font-family: $FontBold;
    text-align: left;
  }

  .label-itens {
    font-family: $FontBold;
  }

  .content-details {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .infs-order {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px;
      align-items: center;

      li:nth-child(1) {
        list-style-type: none;
      }

      li {
        font-size: 17px;
      }
    }
  }

  .order-delivery-details,
  .complet-order {
    padding: 0 !important;
  }

  .remove-background {
    background-color: $white !important;
    border: none;
    box-shadow: none;

    .item,
    .txt-inf {
      background-color: $white !important;
    }
  }

  .bold {
    font-family: $FontBold !important;
  }

  .client-data {
    .align-infs {
      margin-top: 4px;
    }
    .mr {
      margin-left: 4px;
    }
    &.remove-margin {
      margin-bottom: 0px !important;
    }
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .align-infs {
    .txt-inf {
      font-family: $FontNormal !important;
    }
  }
}
