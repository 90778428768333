.store-header {
  transition: background-color 0.5s ease, padding 0.5s ease, height 0.5s ease;

  &__content {
    top: 32px;

    &-conclue-order {
      top: 0px !important;
    }

    img {
      transition: transform 0.5s ease, width 0.5s ease;
    }

    h1 {
      transition: transform 0.5s ease, font-size 0.5s ease;
    }
  }

  &-scrolled {
    height: 127px;

    h1 {
      font-size: 1.25rem !important;
    }
  }
}

.store-header-categories {
  transition: transform 0.3s ease;

  &-visible {
    top: 88px !important;
  }
}

.search-store-product {
  transition: transform 0.3s ease;

  &-show {
    transform: translateY(136px);
  }

  &-hide {
    transform: translateY(0px);
  }

  &-visible {
    top: 178px !important;
  }
}