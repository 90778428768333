.lp-plans-v2-container {
  height: 100%;
  width: 100%;
  max-width: 1440px;

  @media only screen and (max-width: 768px) {
    width: 95%;
    align-items: center;
  }

  @media only screen and (min-width: 1440px) {
    width: 80%;
  }
}