@import '../assets/styles/colors.scss';

html {
  scroll-behavior: smooth;
  scroll-snap-align: center;
  scroll-padding-top: 80px;
}

.landing-page-slider {
  width: 100%;
  overflow-x: hidden;

  .slick-dots {
    bottom: 10px !important;
  }
}
