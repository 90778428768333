@import '../../assets/styles/colors.scss';

.align-input-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.border-radio {
  width: 16px;
  height: 16px;
  border: 1px solid;
  border-color: $red-rose;
  border-radius: 100%;
  position: relative;
}

.circle-radio {
  width: 6px;
  height: 6px;
  background-color: $red-rose;
  border-radius: 100%;
  position: absolute;
  left: 4px;
  top: 4px;
}

.text-input {
  padding-left: 8px;
  font-size: 16px;
  font-weight: 400;
}