@import '../../../../assets/styles/colors.scss';

.plan-checkout-container {
  width: 100%;

  &-checkout-content {
    width: 100%;
    display: inline-flex;
    gap: 4px;
  }

  &-back-btn {
    align-items: center;
    width: 100%;
    margin-top: 16px;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;

    &-checkout-content {
      flex-direction: column-reverse;
      align-items: center;
      gap: 16px;
    }
  }
}

.plan-purchase-container {
  width: 100%;
  border-radius: 16px;
  background-color: $gray-white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  &__texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 100%;

    &-plan-title {
      font-size: 20px;
      padding-bottom: 8px;

      span {
        font-family: $FontBold;
        color: $black;
      }
    }

    &-highlight {
      font-family: $FontBold;
      color: $red-rose;
    }
  }

  &-validity {
    font-size: 14px;
    text-align: center;

    span {
      margin-top: 8px;
      display: block;
      color: $red-rose;
      font-family: $FontBold;
    }
  }
}

.payment-method-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-content {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    gap: 62px;
  }

  &__method-card {
    cursor: pointer;
    width: 160px;
    height: 147px;
    border-radius: 16px;
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    align-items: center;
    justify-content: center;
    display: flex;

    &-selected {
      border: 2px solid $red-rose;
    }
  }

  &-label {
    width: 100%;
    font-family: $FontBold;
    font-size: 18px;
    display: block;
    text-align: center;
    padding-top: 16px;
    cursor: pointer;

    &-selected {
      color: $red-rose;
    }
  }

  &-confirm-method {
    width: 75%;
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    font-family: 'MontserrattBold';
    background-color: $red-rose;
    color: $white;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    margin-top: 16px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
    padding: 16px 0px;
  }
}

.subscription-process {
  &-flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__pix {
    &-loading {
      gap: 16px;
    }
  }

  &-copy-code {
    font-size: 16px;
    font-family: $FontBold;
    color: $red-rose;
    cursor: pointer;
    background: transparent;
    border: none;
    margin-top: 16px;
  }
}

.credit-card-box {
  display: flex;
  flex-direction: column;

  &-title {
    font-size: 20px;
    font-family: $FontBold;
    color: $red-rose;
    margin: 0;
    margin-bottom: 16px;
    text-align: center;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;

    &__box {
      display: flex;
      flex-direction: column;
      border: thin solid $gray;
      border-radius: 4px;
      padding: 4px;
      text-align: center;
      cursor: pointer;

      &:hover {
        border-color: $red-rose;
      }

      &-active {
        border-color: $red-rose;
      }

      &-cc-number {
        font-size: 14px;
        font-family: $FontBold;
        color: $black;
      }

      &-pattern {
        font-size: 12px;
        font-family: $FontNormal;
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}

.contracted-plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;

  &-title {
    font-size: 20px;
    font-family: $FontBold;
    margin: 0;
    margin-bottom: 16px;
    text-align: center;

    span {
      color: $red-rose;
    }
  }
}