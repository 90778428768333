.align-box-complements {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  grid-template-rows: 164px;

  &.empty-complements {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 100%;

  }

  @media only screen and (max-width: 625px) {
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(1, 164px);
  }
}

.box-items {
  height: 164px;
}